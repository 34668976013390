import PropTypes from 'prop-types';
import React from 'react';
import styles from './Label.module.scss';

const Label = ({
  children,
  className = '',
  color = 'primary',
  inverse = false,
  onClick = null,
  ...rest
}) => (
  <div
    className={`${styles.container} ${styles[color]} ${
      inverse ? styles.inverse : ''
    } ${onClick ? styles.clickable : ''} ${className}`}
    onClick={() => (onClick ? onClick() : null)}
    {...rest}
  >
    {children}
  </div>
);

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'danger',
    'darkgrey',
    'info',
    'primary',
    'secondary',
    'success',
    'transparent',
    'purple',
    'white',
    'black',
  ]),
  inverse: PropTypes.bool,
  onClick: PropTypes.func,
  ...React.Component.propTypes,
};

export default Label;
