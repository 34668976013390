import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  Button,
  Radio,
  FormControl,
  FormGroup,
  ControlLabel,
  Checkbox,
} from 'react-bootstrap';
import { formatMoney, convertCurrency } from 'helpers/HelperFunctions';

import SvgIcon from 'components/SvgIcon/SvgIcon';
import styles from './PaymentModal.module.scss';
import { MINIMUM_INITIAL_PITCH_AMOUNT } from 'helpers/globalVariables';
import { LinkContainer } from 'react-router-bootstrap';
import { acceptPitch } from 'redux/modules/briefDetails';
import { connect } from 'react-redux';
import ModalWrapper from './ModalWrapper';

// Helper functions
const getTotalToPay = (brief, pitch, paymentOption, paymentAmount) => {
  let dayRate = Number(pitch.day_rate / 100);
  let hourlyRate = Number(dayRate / 8);

  // Perform currency conversion, if needed
  if (brief.currency !== pitch.day_rate_currency) {
    dayRate = convertCurrency(dayRate, pitch.day_rate_currency, brief.currency);
    hourlyRate = convertCurrency(
      hourlyRate,
      pitch.day_rate_currency,
      brief.currency
    );
  }

  let grandTotal = Number(paymentAmount) || 0;

  if (paymentOption === 'day_rate') {
    grandTotal = Number(dayRate * paymentAmount);
  } else if (paymentOption === 'hourly_rate') {
    grandTotal = Number(hourlyRate * paymentAmount);
  }

  return Math.round(grandTotal * 100) / 100;
};

const renderTotalToPay = (brief, pitch, paymentOption, paymentAmount) => {
  const totalToPay = getTotalToPay(brief, pitch, paymentOption, paymentAmount);

  return formatMoney(totalToPay * 100, brief.currency);
};

const renderHourlyRate = (brief, pitch) => {
  const hourlyRate = Number(pitch.day_rate / 8);
  const hourlyRateInBriefCurrency = convertCurrency(
    hourlyRate,
    pitch.day_rate_currency,
    brief.currency
  );

  return (
    <span>
      <span className="mr--">
        {`Hourly rate – `}
        {`${formatMoney(hourlyRateInBriefCurrency, brief.currency)}`}
      </span>

      {brief.currency !== pitch.day_rate_currency && (
        <span className={styles.infoText}>
          {`(${formatMoney(hourlyRate, pitch.day_rate_currency)})`}
        </span>
      )}
    </span>
  );
};

const renderDailyRate = (brief, pitch) => {
  const dailyRate = Number(pitch.day_rate);
  const dailyRateInBriefCurrency = convertCurrency(
    dailyRate,
    pitch.day_rate_currency,
    brief.currency
  );

  return (
    <span>
      <span className="mr--">
        {`Day rate – `}
        {`${formatMoney(dailyRateInBriefCurrency, brief.currency)} `}
      </span>

      {brief.currency !== pitch.day_rate_currency && (
        <span className={styles.infoText}>
          {`(${formatMoney(dailyRate, pitch.day_rate_currency)})`}
        </span>
      )}
    </span>
  );
};

// Functional sub-components
const ProjectTermsStep = ({
  pitch,
  onNext,
  projectTermsAgreed,
  platformTermsAgreed,
  onTermsCheck,
}) => (
  <ModalWrapper>
    {/*
      Two options here:
        - Pay quoted amount -> redir to payment details view w/ quoted amount (from pitch) to be paid
        - Pay custom amount -> ask additional questions -> determine amount to be paid -> redir to payment details view
    */}
    <div className={styles.initialHireContainer}>
      <div className={styles.termsCheckboxes}>
        <Checkbox
          checked={projectTermsAgreed}
          onChange={() => onTermsCheck('project')}
        >
          I agree to{' '}
          <Link target="_blank" to="/platform-terms">
            {"Twine's Platform Terms"}
          </Link>
        </Checkbox>
        <Checkbox
          checked={platformTermsAgreed}
          onChange={() => onTermsCheck('platform')}
        >
          I agree to{' '}
          <Link target="_blank" to="/engagement-terms">
            Engagement Terms
          </Link>{' '}
          with {pitch.sender.displayname}
        </Checkbox>
      </div>

      <div className={styles.ctaButtonContainer}>
        <div className={styles.ctaButtons}>
          <Button
            bsStyle="success"
            onClick={onNext}
            className={styles.ctaButton}
            disabled={!projectTermsAgreed || !platformTermsAgreed}
          >
            <div className={styles.ctaButtonTextIcon}>
              <span className="mr--"> Next </span>
              <SvgIcon icon="chevron-right" size="16" />
            </div>
          </Button>
        </div>
      </div>
    </div>
  </ModalWrapper>
);

const FeeConfirmationStep = ({
  pitch,
  onNext,
  initialPaymentFeeAgreed,
  onInitialFeeAmountChange,
}) => (
  <ModalWrapper>
    <div className={styles.initialHireContainer}>
      Has an initial payment fee been agreed with {pitch.sender.displayname} and
      would you like to use Twine to manage payments?
      <div className={styles.radioButtonsContainer}>
        <Radio
          checked={initialPaymentFeeAgreed}
          label="Yes"
          className={styles.radio}
          id="initalFeeTrue"
          onChange={onInitialFeeAmountChange}
        >
          Yes
        </Radio>
        <Radio
          checked={!initialPaymentFeeAgreed}
          label="No"
          className={styles.radio}
          id="initalFeeFalse"
          onChange={onInitialFeeAmountChange}
        >
          No
        </Radio>
      </div>
      <p className={`mt+ ${styles.infoText}`}>
        Making your payment through Twine will add additional security to your
        hire and take away the hassle for you to focus on your project goals.
      </p>
      <div className={styles.ctaButtonContainer}>
        <div className={styles.ctaButtons}>
          <Button
            bsStyle="success"
            onClick={onNext}
            className={styles.ctaButton}
          >
            <div className={styles.ctaButtonTextIcon}>
              <span className="mr--">
                {initialPaymentFeeAgreed ? 'Next' : 'Hire'}{' '}
              </span>
              <SvgIcon icon="chevron-right" size="16" />
            </div>
          </Button>
        </div>
      </div>
      <p className={`mt+ ${styles.infoText}`}>
        You can make additional payments to {pitch.sender.displayname} at a
        later date.
      </p>
    </div>
  </ModalWrapper>
);

const MarkedAsHired = ({ pitch, hireOthers, inMessages, closeModal }) => (
  <ModalWrapper>
    <div className={styles.initialHireContainer}>
      <div className="mb+">
        {` You have now marked ${pitch.sender.displayname} as hired 🎉`}
      </div>
      <div className="mb+">
        You can now make a payment to {pitch.sender.displayname} at anytime by
        clicking Pay within Messages on Twine. They may also request a bill.
      </div>
    </div>
    <div className={styles.ctaButtonContainer}>
      <div className={styles.ctaButtons}>
        <Button bsStyle="info" onClick={hireOthers}>
          <div className={styles.ctaButtonTextIcon}>
            <span className="mr--"> Hire Others </span>
            <SvgIcon icon="chevron-right" size="16" />
          </div>
        </Button>
      </div>
      <div className={styles.ctaButtons}>
        <LinkContainer to={!inMessages && '/messages/' + pitch.sender.username}>
          <Button bsStyle="success" onClick={closeModal}>
            <div className={styles.ctaButtonTextIcon}>
              <span className="mr--"> {pitch.sender.displayname} </span>
              <SvgIcon icon="bubble" size="16" />
            </div>
          </Button>
        </LinkContainer>
      </div>
    </div>
  </ModalWrapper>
);

const ConfirmedFeeStep = ({
  brief,
  pitch,
  paymentOption,
  onPaymentOptionChange,
  onNext,
}) => (
  <ModalWrapper>
    <div className={styles.paymentOptionsContainer}>
      <div>{`How would you like to pay ${pitch.sender.displayname}?`}</div>

      <div className={styles.radioButtonsContainer}>
        {/* Specific Amount (paymentOption = 'specific_amount') */}
        <Radio
          checked={paymentOption === 'specific_amount'}
          className={styles.radio}
          id="pay-modal-specific_amount"
          onChange={onPaymentOptionChange}
        >
          {`Specific amount`}
        </Radio>
        {/* Day Rate (paymentOption = 'day_rate') */}
        <Radio
          checked={paymentOption === 'day_rate'}
          className={styles.radio}
          id="pay-modal-day_rate"
          onChange={onPaymentOptionChange}
        >
          {renderDailyRate(brief, pitch)}
        </Radio>
        {/* Hourly Rate (paymentOption = 'hourly_rate') */}
        <Radio
          checked={paymentOption === 'hourly_rate'}
          className={styles.radio}
          id="pay-modal-hourly_rate"
          onChange={onPaymentOptionChange}
        >
          {renderHourlyRate(brief, pitch)}
        </Radio>
      </div>
    </div>

    <div className={styles.ctaButtonContainer}>
      <Button bsStyle="success" onClick={onNext} className={styles.ctaButton}>
        <span className="mr--">Next</span>
        <SvgIcon icon="chevron-right" size="16" />
      </Button>
    </div>
  </ModalWrapper>
);

const PayAndHireStep = ({
  brief,
  pitch,
  paymentOption,
  paymentAmount,
  onPaymentAmountChange,
  onPay,
  isFormValid,
  pitchErrors,
}) => (
  <ModalWrapper>
    {/*
      Depending on `paymentOption`, we now ask for:
        - no. of hours required -> display total -> payment details
        - no. of days required -> display total -> payment details
        - how much money the client wants to pay -> payment details
    */}
    <div className={styles.totalAmountContainer}>
      <div className={`mt`}>
        <FormGroup>
          <ControlLabel className={styles.totalAmountQuestion}>
            {paymentOption === 'day_rate' &&
              `How many days do you need ${pitch.sender.displayname}'s help?`}
            {paymentOption === 'hourly_rate' &&
              `How many hours do you need ${pitch.sender.displayname}'s help?`}
            {paymentOption === 'specific_amount' &&
              `How much would you like to pay ${pitch.sender.displayname}?`}
          </ControlLabel>

          <FormControl
            type="number"
            value={paymentAmount}
            id="total-to-pay-input"
            onChange={onPaymentAmountChange}
            placeholder={
              paymentOption === 'day_rate'
                ? `Number of days`
                : paymentOption === 'hourly_rate'
                ? `Number of hours`
                : `Enter amount`
            }
          />
        </FormGroup>
      </div>

      <div className={styles.totalToPayContainer}>
        <div className={styles.totalToPayLabel}>
          {`Total amount to pay ${pitch.sender.displayname} now`}
        </div>
        <div className={styles.totalToPayValue}>
          {renderTotalToPay(brief, pitch, paymentOption, paymentAmount)}
        </div>
      </div>
    </div>
    <div className={styles.errorTextContainer}>
      {pitchErrors && (
        <span
          className={`${styles.errorText} ${
            pitchErrors.minimumInitialPitchAmount && styles.showErrorText
          }`}
        >
          {`The minimum amount is ${
            brief.currency === 'gbp' ? '£' : '$'
          }${MINIMUM_INITIAL_PITCH_AMOUNT}`}
        </span>
      )}
    </div>
    <div className={styles.initialHireContainer}>
      <p className={`${styles.infoText}`}>
        All payments are secured in the Twine vault until you release them to{' '}
        {pitch.sender.displayname}
      </p>
    </div>
    <div className={styles.ctaButtonContainer}>
      <Button
        bsStyle="success"
        onClick={onPay}
        className={styles.ctaButton}
        disabled={!isFormValid()}
      >
        <span className="mr--">Pay</span>
        <SvgIcon icon="chevron-right" size="16" />
      </Button>
    </div>
  </ModalWrapper>
);

const mapDispatchToProps = (dispatch) => {
  return {
    onAcceptPitch: (brief, pitch) => dispatch(acceptPitch(brief, pitch)),
  };
};
class PaymentModal extends React.Component {
  //

  constructor(props) {
    super(props);

    this.state = {
      currentStep: 1,
      paymentAmount: 0,
      paymentOption: 'specific_amount',
      initialPaymentFeeAgreed: true,
      projectTermsAgreed: false,
      platformTermsAgreed: false,

      pitchErrors: {},
    };

    const { skipProjectTermsStep, skipPaymentFlow } = props;

    if (skipProjectTermsStep) {
      // Goes directly into `pay_specific_amount` flow
      this.state = {
        ...this.state,

        currentStep: 5,
        paymentAmount: MINIMUM_INITIAL_PITCH_AMOUNT,
        paymentOption: 'specific_amount',
      };
    } else if (skipPaymentFlow) {
      // Returns the `Release vaulted funds` view
      this.state = {
        ...this.state,

        currentStep: 4,
        paymentAmount: 0,
        paymentOption: null,
      };
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { skipProjectTermsStep, skipPaymentFlow } = nextProps;

    if (skipProjectTermsStep) {
      // Goes directly into `pay_specific_amount` flow
      this.setState(
        {
          ...this.state,

          currentStep: 3,
          paymentAmount: MINIMUM_INITIAL_PITCH_AMOUNT,
          paymentOption: 'specific_amount',
        },
        this.forceUpdate()
      );
    } else if (skipPaymentFlow) {
      // Returns the `Release vaulted funds` view
      this.setState(
        {
          ...this.state,

          currentStep: 4,
          paymentAmount: 0,
          paymentOption: null,
        },
        this.forceUpdate()
      );
    }
  }

  onTermsCheck = (type = 'project') => {
    const { projectTermsAgreed, platformTermsAgreed } = this.state;

    if (type === 'project') {
      this.setState({
        projectTermsAgreed: !projectTermsAgreed,
      });
    } else {
      this.setState({
        platformTermsAgreed: !platformTermsAgreed,
      });
    }
  };

  onNext = () => {
    const { currentStep, initialPaymentFeeAgreed } = this.state;
    const { pitch, brief, onAcceptPitch } = this.props;

    if (currentStep === 2 && initialPaymentFeeAgreed) {
      this.setState({
        currentStep: currentStep + 2,
      });
      return;
    }

    if (currentStep === 2 && !initialPaymentFeeAgreed) {
      this.setState({
        currentStep: currentStep + 1,
      });
      onAcceptPitch(brief, pitch);
      return;
    }

    if (currentStep === 4) {
      const { paymentOption } = this.state;
      let paymentAmount = 0;
      // Default `day_rate` and `hourly_rate` flows to 1 x unit(hour/day) needed
      if (paymentOption === 'day_rate' || paymentOption === 'hourly_rate') {
        paymentAmount = 1;
      } else {
        if (!pitch.budget || !brief.amount) {
          // Default `specific_amount` flow total price to the asking price of the pitch
          paymentAmount = Number((pitch.budget || brief.amount) / 100);
        } else {
          paymentAmount = 30;
        }
      }

      this.setState(
        {
          currentStep: currentStep + 1,
          paymentAmount: paymentAmount,
        },
        () => this.validateForm()
      );
      return;
    }

    this.setState(
      {
        currentStep: currentStep + 1,
      },
      () => this.validateForm()
    );
  };

  onPay = () => {
    const { pitch, brief, onAccept } = this.props;
    const { currentStep, paymentAmount, paymentOption } = this.state;

    if (currentStep === 5) {
      // Otherwise, get total amount to pay
      const totalToPay = getTotalToPay(
        brief,
        pitch,
        paymentOption,
        paymentAmount
      );
      onAccept(totalToPay);
    }
  };

  onRelease = () => {
    const { pitch, brief, onAccept } = this.props;
    const { currentStep } = this.state;

    // Pay quoted amount directly
    if (currentStep === 4) {
      onAccept(Number((pitch.budget || brief.amount) / 100));
    }
  };

  onPaymentOptionChange = (e) => {
    this.setState(
      {
        paymentOption: e.target.id.split('-')[2],
      },
      () => this.validateForm()
    );
  };

  onInitialFeeAmountChange = () => {
    this.setState(
      {
        initialPaymentFeeAgreed: !this.state.initialPaymentFeeAgreed,
      },
      () => this.validateForm()
    );
  };

  onPaymentAmountChange = (e) => {
    const { value } = e.target;

    let newValue = Number(value) || 0;
    if (newValue && newValue < 0) {
      newValue = 0;
    }

    this.setState(
      {
        paymentAmount: newValue,
      },
      () => this.validateForm()
    );
  };

  validateForm = () => {
    const { pitch, brief } = this.props;
    const { paymentAmount, paymentOption } = this.state;

    const newErrors = {};
    const totalToPay = getTotalToPay(
      brief,
      pitch,
      paymentOption,
      paymentAmount
    );

    // Total to pay needs to be over £/$ 30.
    if (totalToPay < MINIMUM_INITIAL_PITCH_AMOUNT) {
      newErrors.minimumInitialPitchAmount = true;
    }

    if (Object.keys(newErrors).length > 0) {
      this.setState({ pitchErrors: newErrors });
    } else {
      this.setState({ pitchErrors: {} });
    }
  };

  isFormValid = () => {
    return !Object.keys(this.state.pitchErrors).length > 0;
  };

  render() {
    const { brief, pitch, hireOthers, closeModal } = this.props;
    const {
      currentStep,
      paymentOption,
      paymentAmount,
      pitchErrors,
      projectTermsAgreed,
      platformTermsAgreed,
    } = this.state;

    if (!pitch.sender) {
      pitch.sender = pitch.users.sender;
      pitch.sender.displayname = pitch.users.sender.user_displayname
        ? pitch.users.sender.user_displayname
        : pitch.users.sender.username;
    }

    switch (currentStep) {
      case 1:
        return (
          <ProjectTermsStep
            pitch={pitch}
            projectTermsAgreed={projectTermsAgreed}
            platformTermsAgreed={platformTermsAgreed}
            onNext={this.onNext}
            onTermsCheck={this.onTermsCheck}
          />
        );
      case 2:
        return (
          <FeeConfirmationStep
            pitch={pitch}
            initialPaymentFeeAgreed={this.state.initialPaymentFeeAgreed}
            onInitialFeeAmountChange={(e) => this.onInitialFeeAmountChange(e)}
            onNext={this.onNext}
          />
        );
      case 3:
        return (
          <MarkedAsHired
            pitch={pitch}
            brief={brief}
            hireOthers={hireOthers}
            closeModal={closeModal}
          />
        );
      case 4:
        return (
          <ConfirmedFeeStep
            brief={brief}
            pitch={pitch}
            paymentOption={paymentOption}
            onPaymentOptionChange={this.onPaymentOptionChange}
            onNext={this.onNext}
          />
        );
      case 5:
        return (
          <PayAndHireStep
            brief={brief}
            pitch={pitch}
            pitchErrors={pitchErrors}
            paymentOption={paymentOption}
            paymentAmount={paymentAmount}
            onPay={this.onPay}
            onNext={this.onNext}
            isFormValid={this.isFormValid}
            onPaymentAmountChange={this.onPaymentAmountChange}
          />
        );
      default:
        return <span />;
    }
  }
}

ProjectTermsStep.propTypes = {
  pitch: PropTypes.object,
  onNext: PropTypes.func,
  projectTermsAgreed: PropTypes.bool,
  platformTermsAgreed: PropTypes.bool,
  onTermsCheck: PropTypes.func,
};

FeeConfirmationStep.propTypes = {
  pitch: PropTypes.object,
  onNext: PropTypes.func,
  initialPaymentFeeAgreed: PropTypes.bool,
  onInitialFeeAmountChange: PropTypes.func,
};

MarkedAsHired.propTypes = {
  pitch: PropTypes.object,
  hireOthers: PropTypes.func,
  inMessages: PropTypes.bool,
  closeModal: PropTypes.func,
};

ConfirmedFeeStep.propTypes = {
  brief: PropTypes.object,
  pitch: PropTypes.object,
  paymentOption: PropTypes.string,
  onPaymentOptionChange: PropTypes.func,
  onNext: PropTypes.func,
};

PayAndHireStep.propTypes = {
  brief: PropTypes.object,
  pitch: PropTypes.object,
  paymentOption: PropTypes.string,
  paymentAmount: PropTypes.number,
  onPaymentAmountChange: PropTypes.func,
  onPay: PropTypes.func,
  isFormValid: PropTypes.func,
  pitchErrors: PropTypes.object,
};

PaymentModal.propTypes = {
  brief: PropTypes.object,
  pitch: PropTypes.object,
  skipProjectTermsStep: PropTypes.bool,
  skipPaymentFlow: PropTypes.bool,
  onAccept: PropTypes.func.isRequired,
  onAcceptPitch: PropTypes.func,
  hireOthers: PropTypes.func,
  closeModal: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(PaymentModal);
