import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

export default function ModalWrapper({ children }) {
  return (
    <>
      <Row>
        <Col xs={12}>{children}</Col>
      </Row>
    </>
  );
}

ModalWrapper.propTypes = {
  children: PropTypes.array.isRequired,
};
